import { useMatches, UIMatch } from '@remix-run/react';
import { merge } from 'ts-deepmerge';

export default function useDehydratedState() {
  const dehydratedState = useMatches()
    .map((match: UIMatch<any>) => match.data?.dehydratedState)
    .filter((match) => match);

  return dehydratedState.reduce((accumulator, currentValue) => merge(accumulator, currentValue), {});
}
