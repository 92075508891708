import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from '@remix-run/react';
import { dehydrate, HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { Toaster } from 'sonner';
import NiceModal from '@ebay/nice-modal-react';

import useDehydratedState from '@ux/utils/useDehydratedState';
import api from './common/api/api.server';

import 'react-image-crop/dist/ReactCrop.css';
import './tailwind.css';

import { ResultOfOrganizationSiteResponse } from '@ux/api/api-types';
import { LoaderFunctionArgs } from '@remix-run/node';

import '~/common/api/api.client'; // imported so that the axios instance gets extended (base URL set etc.)

export async function loader({ request }: LoaderFunctionArgs) {
  const queryClient = new QueryClient();

  const response = await api.get<ResultOfOrganizationSiteResponse>('/api/v1/site/organization', {
    headers: {
      Origin: request.headers.get('host'),
    },
  });

  queryClient.setQueryData(['site'], response.data.data);

  const dehydratedState = dehydrate(queryClient);

  return {
    ENV: {
      ENV: process.env.ENV,
      API_URL: process.env.API_URL,
      GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
      STREAM_CHAT_API_KEY: process.env.STREAM_CHAT_API_KEY,
    },
    dehydratedState,
  };
}

export const shouldRevalidate = () => false;

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  captureRemixErrorBoundaryError(error);
  return error;
};

export default function App() {
  const data = useLoaderData<typeof loader>();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      })
  );

  const dehydratedState = useDehydratedState();

  return (
    <html lang="en" className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Private Label</title>
        <Meta />
        <Links />
      </head>
      <body>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={dehydratedState}>
            <Toaster />
            <NiceModal.Provider>
              <Outlet />
            </NiceModal.Provider>
          </HydrationBoundary>
        </QueryClientProvider>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}
